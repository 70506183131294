body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
p,
blockquote,
th,
td {
    margin: 0;
    padding: 0;
}

body {
    background: #fff;
    color: #555;
    font-size: 12px;
    font-family: '微软雅黑', Arial, Helvetica, sans-serif;
}

td,
th,
caption {
    font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    font-size: 100%;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
    font-style: normal;
    font-weight: normal;
}

a {
    color: #555;
    text-decoration: none;
}

a:hover {
    /*text-decoration:underline;*/
    text-decoration: none;
}

img {
    border: none;
}

ol,
ul,
li {
    list-style: none;
}

input,
textarea,
select,
button {
    font: 14px '微软雅黑', Verdana, Helvetica, Arial, sans-serif;
    -webkit-appearance: none;
    outline: none;
}

table {
    border-collapse: collapse;
}


.clearfix:after {
    content: '.';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.clearfix {
    *zoom: 1;
}

.container {
    width: 1200px;
    margin: 0 auto;
}

.center {
    text-align: center;
}

.none {
    display: none;
}

.left {
    float: left;
}

.right {
    float: right;
}

.over {
    overflow: hidden;
}
html {
    --antd-wave-shadow-color: none;
}
select {
    /*Chrome和Firefox里面的边框是不一样的，所以复写了一下*/
    border: solid 1px #000;
    /*很关键：将默认的select选择框样式清除*/
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    /*在选择框的最右侧中间显示小箭头图片*/
    background: url('http://ourjs.github.io/static/2015/arrow.png') no-repeat
        scroll right center transparent;
    /*为下拉小箭头留出一点位置，避免被文字覆盖*/
    padding-right: 14px;
}
::selection {
    background: #ff9632 !important;
    color: #000;
    color: #fff;
}
::-moz-selection {
    background: #ff9632 !important;
    color: #000;
    color: #fff;
} /*火狐浏览器*/
::-webkit-selection {
    background: #ff9632 !important;
    color: #000;
    color: #fff;
} /*谷歌*/

/* 设置滚动条的样式 */

::-webkit-scrollbar {
    width: 4px;
}

/* 滚动槽 */

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset006pxrgba(207, 214, 230, 1);
    border-radius: 4px;
}

/* 滚动条滑块 */

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: rgba(161, 168, 182, 1);
    /* -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5); */
}

::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(161, 168, 182, 1);
}

/* 修改谷歌 记住密码 input 样式 */
input:-webkit-autofill {
    box-shadow: 0 0 0 400px #e8eced inset;
    -webkit-box-shadow: 0 0 0 400px #e8eced inset;
}

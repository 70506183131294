@import '../../../styles/style.scss';

.ant-spin-nested-loading,.ant-spin-container{
    height: 100%;
}

.Main_box{
    @include T_wh(100vw,100vh);
    position: relative;
    background: url(../../../img/background_1.png) no-repeat center / 100% 100%;
    box-sizing: border-box;
    overflow: hidden;
    // padding: .95rem .35rem .45rem .35rem;
    // padding-top: .95rem;

    .Main_head{
        width: 15.4rem;
        height: .76rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        background: url(../../../img/titleBack.png) no-repeat scroll center/100% 100%;
        font-size: .24rem;
        text-align: center;
        line-height: .8rem;
        z-index: 200;
        h1{
            line-height: .34rem;
            font-size: .3rem;
            font-weight: bold;
            margin-top:.21rem;
            color: transparent;
            background: linear-gradient(to bottom,#b6b6b6,#ffffff);
            background:-webkit-linear-gradient(to bottom,#b6b6b6,#ffffff); 
            -webkit-background-clip: text;
            background-clip: text;
        }
    }

    .Main_body{
        height: 100%;
        box-sizing: border-box;
        padding: 1.05rem .35rem .45rem .35rem;
        .login_quit{
            width: 0.8rem;
            height: 0.4rem;
            position: absolute;
            top: 0.4rem;
            right: 0.4rem;
            cursor: pointer;
        }
        
        .Main_body_content{
            height: calc(100% - 1rem);
            box-sizing: border-box;
            background-color: #414a5b;
            border-radius: .15rem .15rem 0px 0px ;
            padding: 0.25rem 0.25rem 0;
        }

        .Main_body_footer{
            height: 1rem;
            background-color: #2063B5;
            border-radius: 0px 0px .15rem .15rem;
            @include T_flex_row(flex-start,center);
            padding: .7rem;
            position: relative;
            justify-content: space-between;
            .left{
                flex: 1;
                display: flex;
                align-items: center;
            }
            .right{
                display: flex;
                margin-right: 0.7rem;
                .extend{
                    display: flex;
                    border-right: 2px solid #769FD2;
                    margin-right: 0.55rem;
                    padding-right: 0.4rem;
                    & > div{
                        width: 0.4rem;
                        height: 0.4rem;
                        cursor: pointer;
                        margin-right: 0.15rem;
                    }
                    .luyin{
                        background: url(../../../img/luyinPing_def.png) no-repeat scroll center/100% 100%;
                        // &:hover{
                        //     background: url(../../../img/text_hover.png) no-repeat scroll center/100% 100%;
                        // }
                    }
        
                    .luyinActive{
                        background: url(../../../img/luyinPing_act.png) no-repeat scroll center/100% 100%;
                    }
                    .text{
                        background: url(../../../img/text_default.png) no-repeat scroll center/100% 100%;
                        &:hover{
                            background: url(../../../img/text_hover.png) no-repeat scroll center/100% 100%;
                        }
                    }
                    .textActive{
                        background: url(../../../img/text_active.png) no-repeat scroll center/100% 100%;
                    }
                    .notes{
                        background: url(../../../img/notes_default.png) no-repeat scroll center/100% 100%;
                        &:hover{
                            background: url(../../../img/notes_hover.png) no-repeat scroll center/100% 100%;
                        }
                    }
                    .notesActive{
                        background: url(../../../img/notes_active.png) no-repeat scroll center/100% 100%;
                    }
                    .answer{
                        background: url(../../../img/answer_default.png) no-repeat scroll center/100% 100%;
                        &:hover{
                            background: url(../../../img/answer_hover.png) no-repeat scroll center/100% 100%;
                        }
                    }
                    .answerActive{
                        background: url(../../../img/answer_active.png) no-repeat scroll center/100% 100%;
                    }
                    
                }
                .backAndNextBtn{
                    display: flex;
                    & > div{
                        width: 1.2rem;
                        height: 0.4rem;
                        cursor: pointer;
                    }
                    .back{
                        background: url(../../../img/back_default.png) no-repeat scroll center/100% 100%;
                        &:hover{
                            background: url(../../../img/back_hover.png) no-repeat scroll center/100% 100%;
                        }
                        margin-right: 0.2rem;
                    }
                    .next{
                        &:hover{
                            background: url(../../../img/next_hover.png) no-repeat scroll center/100% 100%;
                        }
                        background: url(../../../img/next_default.png) no-repeat scroll center/100% 100%;

                    }
                }
            }
            .line{
                position: absolute;
                width: 100%;
                height: .5px;
                left:0;
                top:0;
            }
            .img_box{
                @include T_wh(.6rem,.6rem);
                border-radius: 50%; 
                margin-right: .2rem;
                @include T_flex_row();
                overflow: hidden;
                img{
                    width: 100%;
                    min-width: 100%;

                }
            }
            .noSpan{
                font-size: .2rem;
                color: #fff;
                font-family:'Bahnschrift';
                
            }

            .Volume_box{
                @include T_flex_row(flex-start,center);
                margin-left:30px;
                margin-right: 40px;
                .Sound_volume{
                    width: 150px;
                    height: 7px;
                    border-radius:3px;
                    background:rgba(42,70,100,1);
                    overflow: hidden;
                    @include T_flex_row(flex-start,center);
                    .Sound_volume_item{
                        height: 7px;
                        width: 12px;
                        margin-right: 3px;
                        &:last-of-type{
                            margin-right: 0;
                        }
                    }
                    .Sound_volume_item.active{
                        background: #4ba5f8;
                    }
                }
                .Sound_volume_text{
                    font-size: 18px;
                    color: #fff;
                    text-align: left;
                    margin-right: .2rem;
                    width: 160px;
                }
            }

            .Volume_change{
                @include T_flex_row(flex-start,center);
                img{
                    height: 23px;
                    margin-right: 5px;
                }
                .progress{
                    width:1.5rem;
                    .ant-slider{
                        margin:0;
                    }
                    .ant-slider-track{
                        background: #4BA5F8;
                    }
                    .ant-slider-handle{
                        border-color: #fff;
                    }
                }
            }
        }
    }
}


.Menu_box{
    height: 100%;
    overflow: hidden;
    border: solid 1px #90a5c1;
    box-sizing: border-box;
    background: url(../../../img/menu_back.png) no-repeat center / 100% 100%;
    position: relative;

    .Menu_box_list{
        width: 7.7rem;
        margin-top: .7rem;
        margin-left: 1.5rem;

        .Menu_box_list_item{
            height: .55rem;
            background: url(../../../img/part2.png) no-repeat center / 100% 100%;
            background-size: 100% 100%;
            border-radius: .15rem;
            margin-bottom: .4rem;

            @include T_flex_row(flex-start,center);

            span{
                font-size: .24rem;
                color: #fff;
                width: 1.5rem;
                text-align: center;
                margin-right: .25rem;
            }

            p{
                flex: 1;
                font-size: .2rem;
                color: #43525d;
                @extend .T_nowrap ;
            }
        }

        .Start_btn{
            width: 2.6rem;
            height: .5rem;
            background-image: linear-gradient(0deg, 
                #407ebd 0%, 
                #74afdb 100%), 
            linear-gradient(
                #3984c3, 
                #3984c3);
            background-blend-mode: normal, 
                normal;
            box-shadow: 0px 0px .1rem 0px 
                rgba(4, 0, 0, 0.19);
            border-radius: .05rem;
            display: block;
            margin: 0 auto;
            font-size: .24rem;
            color: #fff;
            font-weight: bold;
            text-align: center;
            line-height: .5rem;
            cursor: pointer;
        }
    }
}
.begin_t{
    width: 100%;
    height: 100%;
    background: url(../../../img/bg.png) no-repeat center / cover;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.1rem 0.1rem 0 0 ;
    .begin_s{
        display: flex;
        width: 90%;
        height: 90%;
        border-radius: 0.1rem ;
        background: url(../../../img/beginback.png) no-repeat center / cover;
        .left{
            width: 54%;
        }
        .reight{
            text-align: center;
            width: 46%;
            .enTitle{
                color: #fff;
                font-size: 0.3rem;
                // margin-top: 13%;
                // margin-top: 0.94rem;
                // margin-bottom: 7%;
                // margin-bottom: 0.5rem;
            }
            .sourceName{
                font-size: 0.54rem;
                padding: 0rem 1.1rem;
                line-height: 0.75rem;
                height: 1.5rem;
                color: #CFE9F6;
            }
            .gotostudyBtnBox{
                text-align: center;
                width: 100%;
                // margin-top: 0.65rem;
                .gotostudyBtn{
                    display: inline-block;
                    width: 2.4rem;
                    height: 0.77rem;
                    background-color: #fff;
                    background: url('../../../img/deStartLearningBtn.png') no-repeat ;
                    background-size: 100% 100%;
                    cursor: pointer;
                    &:hover{
                        background: url('../../../img/StartLearningBtn.png') no-repeat ;
                        background-size: 100% 100%;
    
                    }
                }
            }
            .about{
                // margin-top: 1.44rem;
                padding-right: 0.4rem;
                text-align: right;
                color: rgb(238, 230, 230);
                font-size: 0.22rem;
                cursor: pointer;
            }
        }

    }
}

.Analog_box{
    @include T_wh(100vw,100vh);
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    background: #002901;
    .login_quit{
        width: 0.8rem;
        height: 0.4rem;
        position: absolute;
        top: 0.4rem;
        right: 0.4rem;
        cursor: pointer;
    }
    .C_box{
        border: none;
    }
    .Analog_box_content{
        @include T_wh(100vw,100vh);
        box-sizing: border-box;
        padding:1.1rem .16rem .2rem .9rem;
    }
    .C_box.C_box_analog .C_box_left.image .Box .Box_left{
        padding:0;
    }
    .Analog_box_footer{
        width: 100%;
        height: 1.4rem;
        position: absolute;
        bottom:.12rem;
        left:0;
        background: url(../../../img/img_50.png) no-repeat center / cover;
        z-index: 10;


        .Avatar_{
            position: absolute;
            @include T_flex_row(flex-start,flex-start);
            left: .4rem;
            top:-.2rem;
            img{
                width:.6rem;
                height:.6rem;
                border-radius: 50%;
            }
            span{
                font-size:.2rem;
                font-family:Bahnschrift;
                font-weight:400;
                color:rgba(255,255,255,1);
                line-height:.4rem;
                margin-left:.1rem;
            }
        }

        .Analog_Volume_box{
            position: absolute;
            left: .4rem;
            top:.6rem;
            p{
                font-size:.2rem;
                line-height: .2rem;
                font-family:Bahnschrift;
                font-weight:400;
                color:rgba(255,255,255,1);
                margin-bottom:.05rem;
            }
            .Sound_volume{
                width: 150px;
                height: 7px;
                border-radius:3px;
                background:#fff;
                overflow: hidden;
                @include T_flex_row(flex-start,center);
                .Sound_volume_item{
                    height: 7px;
                    width: 12px;
                    margin-right: 3px;
                    &:last-of-type{
                        margin-right: 0;
                    }
                }
                .Sound_volume_item.active{
                    background: #4ba5f8;
                }
            }
        }

        .Analog_Volume_change{
            position: absolute;
            top:.77rem;
            left:3.3rem;
            @include T_flex_row(flex-start,center);
            img{
                height: 23px;
                margin-right: 5px;
            }
            .progress{
                width:1.5rem;
                .ant-slider{
                    margin:0;
                }
                .ant-slider-track{
                    background: #4BA5F8;
                }
                .ant-slider-handle{
                    border-color: #fff;
                }
            }
        }
    }
}



.Explain_box{
    height: 100%;
    box-sizing: border-box;
    border: solid 1px #76889f;
    background: #515e6f;
    padding-top: .2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .Explain_box_img_opi{
        width: 2rem;
        height: 1.45rem;
        display: block;
        margin: 0 auto;
        margin-bottom: .3rem;
        margin-top: 1.4rem;
    }
    
    .Explain_box_img{
        width: 2.66rem;
        display: block;
        margin: 0 auto;
        // margin-top: 1.4rem;
        margin-bottom: .3rem;
    }

    .Explain_box_direction{
        width: 10.8rem;
        margin: 0 auto;
        color: #fff;
        font-size: .18rem;
        font-style: italic;
        em{
            font-size: .24rem;
            font-style: italic;
        }
    }
    
    .Explain_Directions{
        font-size:28px;
        font-family:Microsoft YaHei;
        font-weight:bold;
        font-style:italic;
        color:rgba(255,255,255,1);
        line-height:36px;
        text-align: center;
        margin-bottom:.3rem;
    }

    .Interview_btn{
        width: 3.75rem;
        height: .6rem;
        background-image: linear-gradient(0deg, 
            #4983ca 0%, 
            #66a8d2 100%), 
        linear-gradient(
            #000000, 
            #000000);
        background-blend-mode: normal, 
            normal;
        box-shadow: 0px 0px 7px 0px 
            rgba(4, 0, 0, 0.35);
        border-radius: 5px;
        border: none;
        font-size: .24rem;
        font-style: italic;
        color: #fff;
        margin: 0 auto;
        margin-top: .7rem;
        display: block;
        cursor: pointer;
    }
}

.Explain_box_title{
    width: 13.7rem;
    height: .55rem;
    margin: 0 auto;
    background: url(../../../img/item_back.png) no-repeat center / 100% 100%;
    @include T_flex_row(flex-start);

    span{
        font-size: .24rem;
        color: #fff;
        width: 1.5rem;
        text-align: center;
        margin-right: .25rem;
        font-weight: bold;
    }

    p{
        flex: 1;
        font-size: .2rem;
        color: #43525d;
        @extend .T_nowrap ;
    }
}



.C_box{
    height: 100%;
    box-sizing: border-box;
    border: solid 1px #76889f;
    background: #515e6f;
    @include T_flex_row();

    .C_box_left{
        flex: 1;
        height: 100%;
        box-sizing: border-box;
        padding:.35rem .55rem;
        padding-bottom: 0;
        // overflow: auto;
        position: relative;
        display: flex;
        flex-direction: column;
        .title{
            @extend .Explain_box_title ;
            width: 100%;
            span{
                font-weight: bold;
            }
        }
        &.Change_back{
            @include T_linear(to top,#0D111C,#1D68C5);
            position: relative;
        }
        .Record_box_story{

        }

        .question_content{
            padding-left:38px;
            .title_{
                font-size:20px;
                font-family:Microsoft YaHei;
                font-weight:400;
                color:rgba(255,255,255,1);
                line-height:30px;
                margin-bottom: 30px;
            }
        }

        .Bo_box{
            height: 175px;
            margin-top:1.5rem;
            margin-bottom: 25px;
            .Bo_tu{
                height: 1rem;
                @include T_flex_row();
                span{
                    font-size: .4rem;
                    color: #4ba5f8;
                    font-weight: bold;
                }
        
                .Bo_tu_item{
                    width: .08rem;
                    border-radius: .05rem;
                    background: #fff;
                    margin: 0 3px;
                }
            }
            @include T_flex_column(flex-start,center);
            h1{
                font-size:24px;
                font-family:Microsoft YaHei;
                font-weight:400;
                font-style:italic;
                color:rgba(255,255,255,1);
                margin-left: 25px;
            }
            h2{
                font-size:24px;
                font-family:Microsoft YaHei;
                font-weight:400;
                font-style:italic;
                color:rgba(255,255,255,1);
                margin-bottom: 20px;
            }
            h3{
                font-size:18px;
                // font-family:Microsoft YaHei;
                font-weight:400;
                // font-style:italic;
                color:rgba(255,255,255,1);
                line-height:30px;
            }
            .play_img{
                width:150px;
            }
            .record_img{
                width:206px;
                margin-bottom: 26px;
            }
            .playing_{
                width: 280px;
                height: 100%;
                background: url(../../../img/img_45.png) no-repeat center / 100% 100%;
                font-size:24px;
                font-family:Microsoft YaHei;
                font-weight:400;
                font-style:italic;
                color:rgba(255,255,255,1);
                box-sizing: border-box;
                padding-right: 20px;
                text-align: right;
                line-height: 175px;
            }
        }
        
    }
    .C_box_left.image{
        background: #002B00;
        @include T_flex_column(flex-start,flex-start);
        .Box{
            flex: 1;
            box-sizing: border-box;
            overflow: hidden;
            width: 100%;
            margin-top: .15rem;
            .Box_right{
                @include T_flex_column(flex-start,flex-start);
                height: 100%;
                box-sizing: border-box;
                .wave{
                    width: 6.3rem;
                }

                .Answer_question_progress{
                    width: 5rem;
                }

                .title{
                    background: url(../../../img/part.png) no-repeat left / auto 100%;
                    background-color: #f4f4f4;
                    @include T_border_radius(.11rem);
                }
                .Question_title{
                    margin: 0;
                    // height: .7rem;
                    position: relative;
                }
                .Question_title .count{
                    left: .1rem;
                    top: 0;
                }
                .Question_title .ti{
                    min-width: 2rem;
                }
                .Record_box{
                    margin: 0;
                    position: absolute;
                    left: 50%;
                    bottom: -.8rem;
                    z-index: 10;
                }
            }

            .Box_left{
                height: 100%;
                padding-bottom: .3rem;
                box-sizing: border-box;
                width: 100%;
                @include T_flex_row(space-between,flex-start);
            }
        }
    }

    .C_box_right{
        height: 100%;
        background-color: #485464;
        border-left: solid 1px #76889f;
        width: 3.4rem;
        box-sizing: border-box;
        overflow: auto;

        h1{
            font-size: .24rem;
            color: #eee;
            margin-bottom: .35rem;
            text-align: center;
        }

        .contentP{
            font-size: .16rem;
            line-height: .3rem;
            color: #eee;
            padding: .45rem .3rem;
            padding-bottom: 2rem;
            font-style: italic;
            background: url(../../../img/direction_back.png) no-repeat center 95% / 1.7rem auto;
        }
    }

    &.C_box_analog{
        .C_box_left.image{
            padding: 0;
            .Box {
                margin: 0;
                .Box_left{
                    padding: 0;
                    padding-right: 2.5rem;
                    padding-left: .35rem;
                    @include T_flex_row(flex-start,flex-start);
                    .Box_left_one{
                        width: 5.5rem;
                        height: 100%;
                        overflow: hidden;
                        @include T_flex_column(flex-start,flex-start);
                        margin-right: .1rem;
                        h1{
                            color:#fff;
                            font-size: 24px;
                            font-weight: bold;
                            span{
                                font-weight: bold;
                                font-size: 26px;
                            }
                            margin-bottom: .24rem;
                        }
                        p{
                            color:#fff;
                            font-size: .26rem;
                            font-weight: bold;
                            margin-bottom: .4rem;
                        }
                        .S_img{
                            width: 100%;
                            // height: 100%;
                        }
                        .s_box{
                            width: 100%;
                            height: 4.6444444rem;
                            // overflow: hidden;
                        }
                    }
                    .Box_left_two{
                        flex: 1;
                        height: 100%;
                        // overflow: hidden;
                        .B_img{
                            // height: 100%;
                            // width: 100%;
                            width: 87%;
                        }
                    }
                    
                }   
                .Box_right{
                    .Record_box_analog{
                        position: fixed;
                        z-index: 20;
                        bottom:.17rem;
                        right: initial;
                        left: 5.85rem;
                        .Record_btn{
                            @include T_wh(.5rem,.5rem);
                            @include T_flex_row();
                            @include T_border_radius();
                            margin: 0 auto;
                            background-color: #fff;
                            box-sizing: content-box;
                            border: .12rem solid #999999;
                            img{
                                width: 15px;
                            }
                            .Recorder_time{
                                position: absolute;
                                width: 5rem;
                                left: calc(50% - 2.5rem);
                                text-align: center;
                                bottom: 0.7rem;
                                font-size: 18px;
                                color: #fff;
                            }
                            &.Recording{
                                // border: 12px solid rgba(69, 181, 34,0.4);
                                // @include Animation(recording .8s linear infinite alternate);
                                border: .12rem solid #34A42F;
                                position: relative;
                            }
                        }
                    }
                }
            }
            
        }
    }
}



.Play_progress{
    @include T_wh(95px, 95px);
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    padding: 5px;
    margin-top: 1.55rem;
    margin-bottom: .8rem;
    .img_box{
        @include T_flex_row();
        border-radius: 50%;
        @include T_wh();
        background: #fff;
        position: relative;
        z-index: 5;
        
        img{
            width: 45px;
        }
    }

    #Canvas{
        position: absolute;
        left: 0;
        top: 0;
        @include T_wh(95px, 95px);    
    }
}


.wave{
    display: block;
    width: 10rem;
    margin: 0 auto;
}




.Question_title{
    position: relative;
    @include T_flex_row();
    margin-top: .35rem;
    margin-bottom: -.13rem;

    .count{
        position: absolute;
        font-size: .34rem;
        color: #fff;
        font-weight: bold;
        left: .3rem;
        line-height: .4rem;
        top: calc(50% - .2rem);
    }


    .ti{
        font-size: .28rem;
        color: #fff;
        min-width: 2.5rem;
        box-sizing: border-box;
        padding: 0 .3rem;
        text-align: center;
    }

    img{
        height: .32rem;
    }
}

.Question_playing_img{
    height: 90px;
    display: block;
    margin: 0 auto;
    margin-bottom: .95rem;
}


.Question_option_box{
    overflow: hidden;
    width: 100%;
    .item_box{
        @include T_flex_row(flex-start,flex-start);
        padding-left: 6px;
        .sel{
            width:.2rem;
            height:.2rem;
            background:rgba(255,255,255,1);
            border-radius:3px;
            margin-top:.125rem;
            @include T_flex_row();
            img{
                display: none;
            }
            &.active{
                img{
                    display: block;
                    width: .16rem;
                }
            }
        }
        .op{
            line-height:.45rem;
            width: .4rem;
            text-align: center;
            font-size:.2rem;
            color:#fff;
        }
    }
    .item{
        // width: calc(100% - 1rem);
        flex:1;
        box-sizing: border-box;
        min-height: .45rem;
        background-color: #596677;
        border-radius: 5px;
        padding: .075rem .12rem;
        margin-bottom: .1rem;
        overflow: hidden;
        cursor: pointer;
        span,p{
            font-size:20px;
            color: #fff;
            line-height: 30px;
            min-height: 30px;
        }
        p{
            word-break: break-all;
        }
        &:hover{
            background:rgba(99,116,138,1);
        }
        position: relative;
        img{
            position: absolute;
            height: .27rem;
            left: -.5rem;
            display: none;
        }
    }
    .item.active{
        background-image: linear-gradient(0deg, 
    		#4983ca 0%, 
            #66a8d2 100%), 
        linear-gradient(
            #ffffff, 
            #ffffff);
        img{
            display: block;
        }
        &:hover{
            background-image: linear-gradient(0deg, 
                #4983ca 0%, 
                #66a8d2 100%), 
            linear-gradient(
                #ffffff, 
                #ffffff);
        }
    }
}


.Answer_question_progress{
    width: 6rem;
    margin: 0 auto;
    margin-top: .37rem;
    margin-bottom: .45rem;
    position: relative;
    @include T_flex_column();
    // height: .8rem;
    padding-top:5px;
    img{
        width:90px;
        margin-bottom: .1rem;
    }
    .time_l{
        font-size:20px;
        font-family:Microsoft YaHei;
        font-weight:400;
        // font-style:italic;
        color:rgba(255,255,255,1);
        line-height:30px;
        width: 100px;
        text-align: center;
    }


    .time{
        @include T_wh(.8rem,.8rem);
        @include T_abs(0,calc(50% - .4rem));
        background: url(../../../img/count.png) no-repeat center / 100% 100%;
        text-align: center;
        line-height: .8rem;
        font-size: .22rem;
        color: #333;
    }
}


.Record_box{
    .bo{
        max-width: 100%;
        display: block;
        margin-top: 2.5rem;
    }
    p{
        position: relative;
        font-size: .25rem;
        color: #fff;
        padding: 0.1rem 0 0 0.5rem;
        font-weight: 600;
    }
    h1{
        text-align: center;
        font-size: .16rem;
        color: #a9b6c6;
    }
    .Answer_question_progress{
        margin-bottom: .1rem;
    }
    .Record_btn{
        @include T_wh(0.5rem,0.5rem);
        @include T_flex_row();
        @include T_border_radius();
        margin: 0 auto;
        background-color: #fff;
        box-sizing: content-box;
        border: 12px solid #999999;
        img{
            width: 15px;
        }
        .Recorder_time{
            position: absolute;
            width: 5rem;
            left: calc(50% - 2.5rem);
            text-align: center;
            top: 110px;
            font-size: 18px;
            color: #fff;
        }
        &.Recording{
            // border: 12px solid rgba(69, 181, 34,0.4);
            // @include Animation(recording .8s linear infinite alternate);
            border: 12px solid #34A42F;
            position: relative;
        }
    }

    h2{
        font-size: .18rem;
        color: #fff;
        text-align: center;
    }
}

@keyframes recording{
    from{
        border: .23rem solid rgba(69, 181, 34,0.3);
    }
    to{
        border: .23rem solid rgba(69, 181, 34,0.6);
    }
}
@keyframes ring{
    from{
        transform: rotate(0);
    }
    to{
        transform: rotate(360deg);
    }
}



.No_record{
    @include T_flex_column(flex-start,center);
    img{
        @include T_wh(.95rem,.95rem);
        margin-top: 1.3rem;
        margin-bottom: .45rem;
    }
    p{
        font-size: .18rem;
        color: #7a818c;
        text-align: center;
    }
}




.T_tips{
    position: fixed;
    width: 330px;
	height: 60px;
	border-radius: 3px;
    padding-left: 50px;
    @include T_flex_row(flex-start,center);
    top:100px;
    left: calc(50% - 165px);
    z-index: 222222;
    transition: .5s;
    img{
        height:19px;
        margin-right: 20px;
    }
    span{
        font-size: 18px;
        color: #fff;
    }
    &.success{
        background-color: rgba(0, 0, 0, 0.7);
    }
    &.error{
        background-color: rgba(230,0,18, 0.7);
    }

    &.remove{
        top:-100px;
        opacity: 0;
    }
}



.Mock_box{
    @include T_wh(100vw,100vh);
    overflow: hidden;
    background: url(../../../img/mock_back.png) no-repeat center / cover;
    position: relative;
    z-index: 200;
    @include T_flex_row();
    .Box_m{
        width: 9.7rem;
        box-sizing: border-box;
        background-image: linear-gradient(#fff);
        background-size: cover;
        background-repeat: no-repeat;
        box-shadow: 0px .13rem .27rem 0px 
            rgba(80, 78, 78, 0.59);
        border-radius: .2rem;
        border:.44rem solid rgba(255, 255, 255, 0.5);
        padding: .5rem 0;

        @include T_flex_column(flex-start,center);
        img{
            width: 2.7rem;
            margin-bottom: .4rem;
        }
        h1{
            @include T_font_one(.45rem,.45rem,#333);
            font-weight: bold;
            margin-bottom:.25rem;
        }
        h2{
            width: 5.85rem;
            @include T_font_one(.16rem,.32rem,#666);
            margin-bottom: .7rem;
            text-align: justify;
        }
        button{
            width: 2.5rem;
            height: .6rem;
            background-image: linear-gradient(0deg, 
                #0e77da 0%, 
                #4cbef5 100%);
            box-shadow: 0px .1rem .2rem 0px 
                rgba(21, 133, 231, 0.59);
            border-radius: .3rem;
            cursor: pointer;
            border: none;
            outline: none;
            @include T_font_one(.2rem,.6rem,#fff);
        }
    }
}



.Login_box{
    @include T_wh(100vw,100vh);
    background: url(../../../img/login_back.png) no-repeat center / cover;
    position: relative;
    z-index: 200;
    box-sizing: border-box;
    padding-top: .25rem;
    overflow: hidden;
    .Quit{
        padding-right: .35rem;
        text-align: right;
        margin-bottom: .06rem;
        span{
            width: 1rem;
            height: .36rem;
            border-radius: .18rem;
            border: solid 1px #eeeeee;
            display: inline-block;
            text-align: center;
            line-height: .35rem;
            color: #fff;
            font-size: .16rem;
            cursor: pointer;
        }
        img{
            height: .4rem;
        }
    }

    .Title{
        padding-left: .5rem;
        @include T_flex_row(flex-start,center);
        .logo_{
            height: .45rem;
            margin-right:.25rem;
        }
        .logo_1{
            height: .4rem;
            margin-right:.2rem;
        }
        .logo_2{
            height: .85rem;
        }
        .img_1{
            height: .46rem;
            margin-right: .4rem;
        }
        .img_2{
            height: .48rem;
            margin-right:.5rem;
        }
        .img_3{
            height: .4rem;
            margin-right:.2rem;
        }
        margin-bottom: .6rem;
    }
    .Code_{
        font-size: 14px;
        color: rgba(255, 255, 255, 0.5);
        text-align: right;
        position: absolute;
        right:.3rem;
        bottom:1vw;
        left:0;
        span{
            // float: left;
            // color: #fff;
            text-align: center;
            // text-align: right;
            width: 100%;
            display: inline-block;
            padding-left:25px;
        }
    }

    .Role_check{
        height: 7.5rem;
        padding-left: 4.5rem;
        @include T_flex_row(flex-start,center);
        .di{
            height: 6.6rem;
            transition: .5s;
        }
        position: relative;
        .btn_{
            height: .9rem;
            width:4.9rem;
            position: absolute;
            bottom:.5rem;
            cursor: pointer;
        }
        .R_candidate{
            position: relative;
            .btn_{
                left: -2rem;
                background: url(../../../img//candidate_login.png) no-repeat center / cover;
                &:hover{
                    background: url(../../../img//candidate_login_hover.png) no-repeat center / cover;
                }
            }
        }
        .R_examination{
            position: relative;
            .btn_{
                left:.85rem;
                background: url(../../../img//examination_login.png) no-repeat center / cover;
                &:hover{
                    background: url(../../../img//examination_login_hover.png) no-repeat center / cover;
                }
            }
        }
        >div{
            &:hover{
                .di{
                    height: 7.5rem;
                }
            }
        }
    }

    .SelRole_box{
        margin:0 auto;
        margin-top:.45rem;
        width: 9.6rem;
        height: 69vh;
        background-color: #dae2e9;
        box-shadow: 0px 0px .1rem 0px 
            rgba(0, 0, 0, 0.39);
        border-radius: .15rem;
        box-sizing: border-box;
        padding: .22rem;
        padding-top: 0;
        @include T_flex_column(flex-start,center);
        position: relative;
        &:after{
            content: '';
            position: absolute;
            width: 103%;
            height: 103%;
            left: -1.5%;
            top:-1.5%;
            background: url(../../../img/login_box_back.png) no-repeat center / 100% 100%;
            z-index:-1;
        }
        .S_title{
            width: 100%;
            text-align: center;
            line-height: .5rem;
            font-size: .25rem;
            color: #fff;
            position: relative;
            z-index: 5;
            height: .9rem;
            // &:after{
            //     content: '';
            //     position: absolute;
            //     z-index: -1;
            //     height: .8rem;
            //     width: 3.4rem;
            //     left: calc(50% - 1.7rem);
            //     border-radius: 0 0 .1rem .1rem;
            //     top:0;
            //     background-image: linear-gradient(0deg, 
            //     #768494 0%, 
            //     #8c97a3 100%);
            //     transform-origin: top center;
            //     transform:perspective(.5em) rotateX(-4deg);
            // }
            background: url(../../../img/sel_back.png) no-repeat center top / auto .5rem;
        }
        .S_content{
            height: calc(100% - .9rem);
            width: 100%;
            background-color: #3e4550;
            border-radius: 5px;
            border: solid 1px #5d626e;
            @include T_flex_column();

            .ant-input{
                height: .45rem;
                line-height: .45rem;
                color: #333;
            }

            .S_content_1{
                width: 4rem;
                @include T_flex_row(space-between,center);
                .M_code{
                    width: 1.15rem;
                    height: .45rem;
                    background-color: #ffffff;
                    border-radius: .05rem;
                    border: solid 1px #dcdcdc;
                }
            }
            .Detail_{
                font-size: .16rem;
                color: #fff;
                margin-top:.55rem;
                cursor: pointer;
            }
            .S_item{
                width: 100%;
                box-sizing: border-box;
                padding-left: 2.7rem;
                font-size: .26rem;
                color: #fff;
                margin-bottom: .2rem;
            }
            .Check_item{
                width: 100%;
                box-sizing: border-box;
                padding-left: 2.2rem;
                @include T_flex_row(flex-start,center);
                font-size: .18rem;
                color:#fff;
                margin-top: 1rem;
                margin-bottom: .5rem;
                span{
                    @include T_wh(.25rem,.25rem);
                    background: #fff;
                    border-radius: .05rem;
                    margin-right:.13rem;
                    img{
                        height: .25rem;
                        transform: translateY(-.05rem);
                    }
                }
            }

            .Video_box{
                @include T_wh();
                overflow: hidden;
                position: relative;
                video{
                    height: 100%;
                    width: 100%;
                    background: rgb(65, 64, 64);
                }
                position: relative;
                .face_image{
                    position: absolute;
                    top:0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: -1;
                    width: 100%;
                    height: 100%;
                }
                .frame{
                    position: absolute;
                    width: 70%;
                    height: 70%;
                    left: 15%;
                    top: 15%;
                    z-index: 10;
                    div{
                        position: absolute;
                        width: 50px;
                        height: 50px;
                        border: 2px solid #b2b3b5;
                    }
                    .one{
                        left: 0;
                        top: 0;
                        border-width: 2px 0 0 2px;
                    }
                    .two{
                        top: 0;
                        right: 0;
                        border-width: 2px 2px 0 0;
                    }
                    .three{
                        bottom: 0;
                        right: 0;
                        border-width: 0 2px 2px 0;
                    }
                    .four{
                        bottom: 0;
                        left: 0;
                        border-width: 0 0 2px 2px;
                    }
                }
                p{
                    position: absolute;
                    bottom: 10px;
                    text-align: center;
                    left: 0;
                    right: 0;
                    font-size: 18px;
                    color: #0e77da;
                }
            }

            .fail_box{
                @include T_flex_column();
                .one{
                    width: 100%;
                    @include T_flex_row(center,flex-end);
                    margin-bottom: 1rem;
                    img{
                        width: 2rem;
                        margin-right: .33rem;
                    }
                    .detail{
                        width: 3.7rem;
                        color: #fff;
                        h1{
                            font-size: .36rem;
                            color: #fff;
                            margin-bottom: .1rem;
                        }
                        p{
                            font-size: .17rem;
                            line-height: .32rem;
                            span{
                                color: #fd3737;
                            }
                        }
                    }
                }
            }

            .back{
                @include T_flex_row();
                margin-top: .5rem;
                cursor: pointer;
                img{
                    width: .2rem;
                    margin-right: .1rem;
                }
                span{
                    font-size: .16rem;
                    color:#fff;
                }
            }
        }
    }

    .SelRole_box_new{
        width:15.5rem;
        height: 6.5rem;
        margin:0 auto;
        background: url(../../../img/sel_back_new.png) no-repeat center / cover;
        .S_title{
            font-size: .3rem;
            color: #fff;
            text-align: center;
            font-weight: bold;
            font-style: italic;
            padding-top: .55rem;
            margin-bottom: .75rem;
            height: .3rem;
        }
        .S_content{
            margin:0 auto;
            margin-top:1.6rem;
            min-height: 1.3rem;
            .fail_box{
                @include T_flex_column();
                .one{
                    width: 100%;
                    @include T_flex_row(center,flex-end);
                    margin-bottom: 1rem;
                    img{
                        width: 2rem;
                        margin-right: .33rem;
                    }
                    .detail{
                        width: 3.7rem;
                        color: #fff;
                        h1{
                            font-size: .36rem;
                            color: #fff;
                            margin-bottom: .1rem;
                        }
                        p{
                            font-size: .17rem;
                            line-height: .32rem;
                            span{
                                color: #fd3737;
                            }
                        }
                    }
                }
            }
        }
        .S_content_new{
            @include T_flex_column();
            width: 3.1rem;
            margin:0 auto;
            margin-top:1.4rem;
            margin-bottom: .4rem;
            min-height: 1.3rem;
            .S_form_item{
                position: relative;
                margin-bottom:.25rem;
                width: 100%;
                @include T_flex_row(space-between,center);
                label{
                    position: absolute;
                    line-height: .36rem;
                    font-size: .16rem;
                    color:#fff;
                    top:0;
                    right: calc(100% + .2rem);
                    white-space: nowrap;
                }
                .ant-input{
                    height: .36rem;
                    line-height: .36rem;
                }
                .M_code{
                    width: 1.15rem;
                    height: .36rem;
                    background-color: #ffffff;
                    border-radius: .05rem;
                    border: solid 1px #dcdcdc;
                    margin-left: .1rem;
                }
            }
        }
        .S_content_video{
            margin:0 auto;
            margin-top:.5rem;
            width: 6.3rem;
            height: 4.3rem;
            box-sizing: border-box;
            padding:.15rem;
            background: url(../../../img/face_back.png) no-repeat center / cover;
            .Video_box{
                @include T_wh();
                background: #000000;
                overflow: hidden;
                position: relative;
                video{
                    height: 100%;
                    width: 100%;
                    background: rgb(65, 64, 64);
                }
                position: relative;
                .face_image{
                    position: absolute;
                    top:0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: -1;
                    width: 100%;
                    height: 100%;
                }
                .cover_{
                    position: absolute;
                    left: 0;
                    top:0;
                    width: 100%;
                    height: 100%;
                }
                .biopsyImage{
                    position: absolute;
                    right: .15rem;
                    bottom: .15rem;
                    width:1.3rem;
                }
            }
            position: relative;
            .tips{
                position: absolute;
                height: 1.4rem;
                width:2.15rem;
                bottom:5px;
                left: -2.3rem;
                background: url(../../../img/face_tips.png) no-repeat center / cover;
                @include T_flex_row();
                font-size: .18rem;
                color: #fff;
                line-height: .3rem;
                text-align: center;
            }
        }
        .S_content_info{
            width: 9.6rem;
            margin:0 auto;
            margin-top:1.2rem;
            min-height: 1.3rem;
            position: relative;
            .avatar{
                position: absolute;
                left:1.5rem;
                width:140px,
            }
            .S_item{
                width: 100%;
                box-sizing: border-box;
                font-size: .2rem;
                color: #fff;
                margin-bottom: .2rem;
                padding-left: 3.5rem;
            }
            .Check_item{
                width: 100%;
                box-sizing: border-box;
                padding-left: 3.5rem;
                @include T_flex_row(flex-start,center);
                font-size: .14rem;
                color:#fff;
                margin-top: .2rem;
                margin-bottom: .3rem;
                span{
                    @include T_wh(.2rem,.2rem);
                    background: #fff;
                    border-radius: .05rem;
                    margin-right:.13rem;
                    @include T_flex_row();
                    img{
                        height: .12rem;
                    }
                }
            }
        }
        .Btn_next{
            width: 2.1rem;
            height: .6rem;
            border: none;
            font-size: .16rem;
            color: #fff;
            margin:0 auto;
            display: block;
            background: url(../../../img/btn_next.png) no-repeat center / cover;
            &:hover{
                background: url(../../../img/btn_next_hover.png) no-repeat center / cover;
            }
        }
        .S_content{
            height: 2.6rem;
            @include T_flex_row();
            margin-bottom: .5rem;
            .S_item{
                width:1.8rem;
                height:2.6rem;
                transition: .3s;
                &:hover{
                    background:rgba(15,75,171,1);
                    border:1px solid rgba(36,121,252,1);
                    box-shadow:0px 0px .24rem 0px rgba(0, 0, 0, 0.54);
                    border-radius:.08rem;
                }
                @include T_flex_column();
                img{
                    width: 1.3rem;
                    margin-bottom: .2rem;
                }
                p{
                    font-size: .16rem;
                    color: #fff;
                }
            }
        }
        .back{
            @include T_flex_row();
            margin-top: .3rem;
            cursor: pointer;
            img{
                width: .2rem;
                margin-right: .1rem;
            }
            span{
                font-size: .16rem;
                color:#fff;
            }
        }
    }
}

.ExamBreakBox{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
}

.Test_equipment{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    background: #383b43;
    @include T_flex_row(center,flex-start);
    padding-top: .95rem;


    .Test_type{
        @include T_flex_column();
        width:1.6rem;
        .Test_type_item{
            @include T_flex_row();
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @include T_wh(1.4rem,1.26rem);
            margin-bottom: .3rem;

            img{
                @include T_wh(.66rem,.66rem);
                margin-bottom: .1rem;
            }
            span{
                font-size: .16rem;
                color: #ddd;
            }
        }
        .Test_type_item.active{
            background-color: #323947;
            border-radius: 10px;
            border: solid 1px #7ecef4;
        }
    }

    .Test_content{
        width: 10.5rem;
        min-height: 6rem;
        border-radius: 3px;
        box-sizing: border-box;
        border: .15rem solid #e5e5e5;
        margin-left: .25rem;
    }

    .Text_box{
        padding: .3rem .55rem 0 .55rem;
        .one{
            @include T_flex_row();
            span{
                font-size: .2rem;
                color: #fff;
            }
            select{
                height: .35rem;
                border-radius: 5px;
                border: none;
                box-sizing: border-box;
                flex: 1;
                margin-left: 5px;
                padding: 0 .2rem;
                background: url('../../../img/down.png') no-repeat 98% center / .2rem auto;
                background-color: #ffffff;
            }
        }
        .content{
            background-color: #444a57;
            border-radius: 5px;
            border: solid 1px #434343;
            padding: .17rem;
            margin-bottom: .3rem;
            font-size: .16rem;
            color: #ddd;
            line-height: .3rem;
        }
        .four{
            @include T_flex_row();
            margin-bottom: .35rem;
            .four_one{
                width: 1.5rem;
                height: .4rem;
                border-radius: 5px;
                border: solid 1px #7ecef4;
                font-size: .18rem;
                color: #fff;
                background: transparent;
                box-sizing: border-box;
            }
            .four_two{
                width: 1.5rem;
                height: .4rem;
                background-color: #7ecef4;
                border-radius: 5px;
                margin-left: .2rem;
                border: none;
                font-size: .18rem;
                color: #333;
            }
        }
    }
    .Speaker_box{
        .two{
            margin: 0 auto;
            width: 2.1rem;
            height: .5rem;
            background-color: #7ecef4;
            box-shadow: 1px 3px 8px 0px 
                rgba(0, 0, 0, 0.39);
            border-radius: 5px;
            border: none;

            @include T_flex_row();
            margin-top: .35rem;
            margin-bottom: .14rem;

            img{
                width: .22rem;
            }
            span{
                font-size: .2rem;
                color: #333;
                margin-left: .15rem;
            }
        }

        .three{
            text-align: center;
            font-size:.16rem;
            color: #ffffff;
            margin-bottom: .6rem;
        }

        
        
    }

    .Microphone_box{
        .two{
            @include T_flex_row(flex-start,center);
            margin: .65rem 0;
            span{
                font-size: .18rem;
                color: #fff;
                flex: 1;
            }

            .item{
                @include T_wh(.1rem,.45rem);
                background: #474c57;
                margin-left: .075rem;
                border-radius: .05rem;
            }
            .item.show{
                background: #278c21;
            }
        }
    }

    .Camera_box{
        .one{
            span{
                width: 1.2rem;
            }
        }
        .c_two{
            padding: .35rem 0;
            padding-left: calc(1.2rem + 5px);
            @include T_flex_row(flex-start,center);

            .video_box{
                width: 3rem;
                height: 2.25rem;
                background-color: #e9e9e9;
                margin-right: .7rem;
                @include T_flex_row();
                position: relative;
                >img{
                    width: .67rem;
                    position: absolute;
                    left: calc(50% - .335rem);
                    top: calc(50% - .35rem);
                    z-index: 2;
                }
                video{
                    @include T_wh();
                }
            }

            .btn{
                @include T_flex_column();
                button{
                    width: 2.1rem;
                    height: .5rem;
                    background-color: #7ecef4;
                    box-shadow: 1px 3px 8px 0px 
                        rgba(0, 0, 0, 0.39);
                    border-radius: 5px;
                    border: none;
                }
                span{
                    font-size: .16rem;
                    color: #fff;
                    margin-top: .17rem;
                }
            }
        }
    }

    .Report_box{
        .R_title{
            @include T_flex_row();
            margin-top: .6rem;
            margin-bottom: .2rem;
            padding-left: .5rem;
            >span{
                flex: 1;
                font-size: .2rem;
                color: #fff;
            }
        }

        .R_content{
            background-color: #444a57;
            border: solid 1px #656f83;
            border-radius: 5px;

            .R_title{
                margin:0;
                height:.46rem;
                border-bottom: 1px solid #656f83;
            }
            .R_title::last-of-type{
                border: none;
            }
        }

        .four{
            margin-top: 1.45rem;
        }
    }
}




.EquipmentInspection_login_check{
    position: fixed;
    top: 0;
    left:0;
    right:0;
    bottom:0;
    background: rgba(0, 0, 0, 0.7);
    @include T_flex_column(center,center);
    z-index: 20;
    .title{
        background: url(../../../img/img_30.png) no-repeat center / auto 13px;
        font-size: 26px;
        color: #fff;
        width:400px;
        text-align: center;
        margin-bottom: .5rem;
        
    }

    .Box_{
        width: 11.5rem;
        height: 6.6rem;
        box-sizing: border-box;
        // background: linear-gradient(-60deg,rgba(74,82,95,1),rgba(74,82,95,0.3));
        background: linear-gradient(60deg,#45474a,#535e6b);
        border-radius: 5px;
        padding:.15rem;
        position: relative;
        .close{
            position: absolute;
            width: .4rem;
            right: -.6rem;
            top:-.7rem;
        }
        .box{
            width: 100%;
            height: 100%;
            background-color: #383d49;
            border-radius: 5px;
            box-sizing: border-box;
            padding: .6rem 1.3rem;
            padding-bottom: 0;  
            
            
            .tabs_tab{
                @include T_flex_row(flex-start,center);
                margin-bottom: .47rem;
                padding: 0 .7rem;   
                .tab{
                    cursor: pointer;
                    width: 1.4rem;
                    height: 1.25rem;
                    background-color: #40444d;
                    border-radius: .1rem;
                    padding-top: .15rem;
                    box-sizing: border-box;
                    @include T_flex_column(flex-start,center);
                    position: relative;
                    margin-right: 1.35rem;
                    &:last-of-type{
                        margin-right: 0;
                    }
                    .tu{
                        height: .65rem;
                        margin-bottom: .15rem;
                        filter: grayscale(1);
                    }
                    span{
                        font-size: .16rem;
                        color: #dddddd;
                    }
                    .show_type{
                        position: absolute;
                        height: .23rem;
                        right:.05rem;
                        top:.05rem;
                    }
                    &.check{
                        .tu{
                            filter: grayscale(0);
                        }
                        span{
                            color:#fff;
                        }
                    }
                    &.current{
                        background-image: linear-gradient(0deg, 
                            #a7bace 0%, 
                            #fefefe 100%), 
                        linear-gradient(
                            #ffffff, 
                            #ffffff);
                        box-shadow: 0px 0px 5px 0px 
                            rgba(0, 0, 0, 0.16);
                        .tu{
                            filter: grayscale(0);
                        }
                        span{
                            color:#475565;
                        }
                        &:after{
                            content:'';
                            position: absolute;
                            border-bottom: .17rem solid #444B5A;
                            border-left: .19rem solid transparent;
                            border-right: .19rem solid transparent;
                            top:calc(100% + .3rem);
                        }
                    }
                }
            }
            .tabs_content{
                height: 3.1rem;
                background: #444B5A;
                border-radius: 5px;
                &.none_{
                    background: transparent;
                }
                .S_box_{
                    @include T_flex_column();
                    height: 100%;

                    p{
                        font-size:20px;
                        color:#fff;
                        margin-bottom: .3rem;
                    }
                    .start{
                        width: 160px;
                        height: 48px;
                        background-color: #d34646;
                        box-shadow: 0px 3px 8px 0px 
                            rgba(0, 0, 0, 0.15);
                        border-radius: 5px;
                        font-size: 20px;
                        color:#fff;
                        border: none;
                    }
                    img{
                        width:.68rem;
                        margin-bottom: .15rem;
                    }
                }
                .btn_g_{
                    @include T_flex_row();
                    button{
                        font-size: 18px;
                        cursor: pointer;
                        &:first-of-type{
                            width: 150px;
                            height: 40px;
                            background-image: linear-gradient(0deg, 
                                #2961a6 0%, 
                                #4a88d5 100%), 
                            linear-gradient(
                                #7ecef4, 
                                #7ecef4);
                            background-blend-mode: normal, 
                                normal;
                            box-shadow: 0px 0px 5px 0px 
                                rgba(0, 0, 0, 0.16);
                            border-radius: 5px;
                            border: none;
                            color:#fff;
                            margin-right:.35rem;
                        }
                        &:last-of-type{
                            width: 150px;
                            height: 40px;
                            border-radius: 5px;
                            border: solid 1px #fff;
                            color:#fff;
                            background: transparent;
                        }
                        &.one_1{
                            width: 150px;
                            height: 40px;
                            background-image: linear-gradient(0deg, 
                                #2961a6 0%, 
                                #4a88d5 100%), 
                            linear-gradient(
                                #7ecef4, 
                                #7ecef4);
                            background-blend-mode: normal, 
                                normal;
                            box-shadow: 0px 0px 5px 0px 
                                rgba(0, 0, 0, 0.16);
                            border-radius: 5px;
                            border: none;
                            color:#fff;
                        }
                        &.r{
                            width: 150px;
                            height: 40px;
                            background-image: linear-gradient(0deg, 
                                #2961a6 0%, 
                                #4a88d5 100%), 
                            linear-gradient(
                                #7ecef4, 
                                #7ecef4);
                            background-blend-mode: normal, 
                                normal;
                            box-shadow: 0px 0px 5px 0px 
                                rgba(0, 0, 0, 0.16);
                            border-radius: 5px;
                            border: none;
                            color:#fff;
                            margin-right:.35rem;
                        }
                    }
                }
                .M_box_{
                    @include T_flex_column();
                    height: 100%;
                    .two_box{
                        .recorded_{
                            width: 100%;
                            margin-bottom: .3rem;

                            @include T_flex_row(space-between,center);
                            span{
                                font-size: 20px;
                                color:#fff;
                            }
                            button{
                                width:80px;
                                height:35px;
                                background:rgba(68,75,90,1);
                                border:1px solid rgba(187,187,187,1);
                                border-radius:5px;
                                background: transparent;
                                img{
                                    height: 17px;
                                }
                                font-size: 16px;
                                color:#fff;
                                cursor: pointer;
                            }
                        }
                        .two{
                            @include T_flex_row(flex-start,center);
                            span{
                                font-size: .2rem;
                                color: #666;
                                flex: 1;
                                margin-right:10px;
                            }
                
                            .item{
                                @include T_wh(.1rem,.45rem);
                                background: #ddd;
                                margin-left: .075rem;
                                border-radius: .05rem;
                            }
                            .item.show{
                                background: #28D128;
                            }
                            margin-bottom: .6rem;
                        }
                    }
                    
                    p{
                        font-size:20px;
                        color:#fff;
                        margin-bottom: .3rem;
                        &.record{
                            margin: 0;
                            font-size: 16px;
                            color: #28D128;
                        }
                    }
                }
                .C_box_{
                    @include T_flex_row(space-between,center);
                    height: 100%;
                    padding:0 .95rem;
                    video{
                        width: 2.85rem;
                        height: 2.2rem;
                        background-color: #eeeeee;
                        border: solid 1px #dddddd;
                        margin-right:.45rem;
                    }
                    .content{
                        @include T_flex_column();
                        p{
                            font-size:20px;
                            color:#fff;
                            margin-bottom: .3rem;
                        }
                    }
                }
                .R_box_{
                    .R_title{
                        @include T_flex_row();
                        margin-top: .6rem;
                        margin-bottom: .2rem;
                        >span{
                            flex: 1;
                            font-size: .2rem;
                            color: #fff;
                            text-align: center;
                        }
                    }
            
                    .R_content{
                        background-color: #444a57;
                        border: solid 1px #656f83;
                        border-radius: 5px;
            
                        .R_title{
                            margin:0;
                            height:.46rem;
                            border-bottom: 1px solid #656f83;
                        }
                        .R_title::last-of-type{
                            border: none;
                        }
                    }
            
                    .four{
                        margin-top: 1.05rem;
                        @include T_flex_row();
                        font-size:18px;
                        .four_one{
                            width:150px;
                            height:40px;
                            background:linear-gradient(0deg,rgba(167,186,206,1),rgba(254,254,254,1));
                            box-shadow:0px 0px 5px 0px rgba(0, 0, 0, 0.16);
                            border-radius:5px;
                            margin-right:.35rem;
                            color:#333333;
                            cursor: pointer;
                        }
                        .four_two{
                            box-sizing: border-box;
                            width:150px;
                            height:40px;
                            border:1px solid rgba(255,255,255,1);
                            border-radius:5px;
                            background: transparent;
                            cursor: pointer;
                            color:#fff;
                        }
                    }
                }

                .R_ready{
                    text-align: center;
                    height: 100%;
                    @include T_flex_column();
                    h1{
                        font-size:20px;
                        font-family:Microsoft YaHei;
                        font-weight:400;
                        color:rgba(255,255,255,1);
                        margin-bottom: .3rem;
                    }
                    .btn_g_1{
                        button{
                            width:160px;
                            height:48px;
                            border-radius:5px;
                            &.one_1{
                                background:rgba(211,70,70,1);
                                box-shadow:0px 3px 8px 0px rgba(0, 0, 0, 0.15);
                                color: #fff;
                                font-size: 18px;
                                border: none;
                            }
                            &.one_2{
                                border:1px solid rgba(255,255,255,1);
                                box-shadow:0px 3px 8px 0px rgba(0, 0, 0, 0.15);
                                background: transparent;
                                border-radius:5px;
                                font-size: 18px;
                                color: #DDDDDD;
                                margin-left: 15px;
                            }
                        }
                    }
                }
            }

            .Error_info{
                @include T_flex_row(space-between,center);
                margin-top:.3rem;
                p{
                    font-size: 14px;
                    color:#fff;
                }
                span{
                    font-size: 18px;
                    color: #5399F1;
                    cursor: pointer;
                }
            }
        }
    }

    
}

.Cover_t{
    position: fixed;
    top: 0;
    left:0;
    right:0;
    bottom:0;
    z-index: 20;
}
.Pass_tips{
    width:450px;
    min-height:219px;
    background:rgba(65,74,91,1);
    border:1px solid rgba(71,85,103,1);
    box-shadow:0px 0px 15px 0px rgba(4,0,0,0.42);
    border-radius:3px;
    box-sizing: border-box;
    position: fixed;
    left: calc(50% - 225px);
    top:calc(50% - 110px);
    z-index: 25;
    @include T_flex_column(flex-start,flex-start);
    padding-bottom: 25px;
    >*{
        width: 100%;
    }
    .title_{
        width: 100%;
        height:40px;
        background:rgba(52,60,75,1);
        border-radius:3px 3px 0px 0px;
        @include T_flex_row(flex-start,center);
        box-sizing: border-box;
        padding:0 25px;
        span{
            font-size:14px;
            font-family:Microsoft YaHei;
            font-weight:400;
            color:rgba(221,221,221,1);
            margin-right: auto;
        }
        img{
            height: 10px;
        }
    }
    .content_{
        box-sizing: border-box;
        padding: 25px;
        flex: 1;
        width: 100%;
        color: #DDDDDD;
        h1{
            color: #DDDDDD;
            font-size: 16px;
            margin-bottom: 15px;
        }
        p{
            font-size: 14px;
        }
    }

    .f_btn{
        @include T_flex_row(flex-end,center);
        padding-right: 25px;
        button{
            width:100px;
            height:36px;
            margin-left: 15px;
            font-size: 14px;
            color:#fff;
            &.one_1{
                background:linear-gradient(0deg,rgba(41,97,166,1),rgba(74,136,213,1));
                box-shadow:0px 0px 5px 0px rgba(0, 0, 0, 0.16);
                border-radius:5px;
                border: none;
            }
            &.one_2{
                border:1px solid rgba(88,105,128,1);
                box-shadow:0px 0px 5px 0px rgba(0, 0, 0, 0.16);
                border-radius:5px;
                background: transparent;
            }
        }
    }
}


.Error_page{
    @include T_wh();
    @include T_flex_column();
    >img{
        height: 240px;
        margin-bottom: .5rem;
    }
    p{
        font-size: 24px;
        color: #fff;
        margin-bottom: .45rem;
    }
    .btn_error_{
        @include T_flex_row();
        button{
            width: 2.4rem;
            height: .7rem;
            border: none;
            border-radius: .05rem;
            font-size:.24rem;
            color: #fff;
            font-weight: 600;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);

        }

        .one{
            border: 1px solid #337c51;
            background: linear-gradient(to top,#0d9449,#45c277);
            transition: .3s;
            &:hover{
                border: 1px solid #337c51;
                background: linear-gradient(to top,#1dab5b,#57d188);
            }
        }

        .two{
            margin-left: .25rem;
            border: 1px solid #535c67 ;
            background: linear-gradient(to top,#677689,#9baabd );
            transition: .3s;
            &:hover{
                border: 1px solid #337c51;
                background: linear-gradient(to top,#7f8ea2,#acbcd0);
            }
        }
    }
}

.Cover_over{
    position: fixed;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3000;
    background: rgba(0, 0, 0, 0.7);
}
.Over_tips{
    position: fixed;
    z-index: 3005;
	box-shadow: 0px 0px 35px 0px 
		rgba(4, 0, 0, 0.35);
    border-radius: 5px;
    left: calc(50% - 240px);
    top: calc(50% - 310px);
    box-sizing: border-box;
    padding-top: 70px;

    width:480px;
    height:620px;
    background:rgba(57,132,195,1);
    @include T_flex_column(flex-start,center);
    

    .fly{
        display: block;
        margin: 0 auto;
        width:338px;
    }
    .t{
        height:200px;
        margin-bottom:7px;
    }
    color:#fff;
    h1{
        font-size:28px;
        margin-bottom:15px;
        color:#fff;
    }
    h2{
        font-size:16px;
        color:#fff;
        margin-bottom:50px;
    }
    button{
        width:135px;
        height:40px;
        background:rgba(190,25,25,1);
        border-radius:3px;
        border:none;
        font-size:18px;
        color:#fff;
        margin-bottom:15px;
    }
    h3{
        font-size:14px;
        color:#fff;
        margin-bottom:7px;
    }
}

.Complete_box{
    height: 100%;
    background: #505E6F;
    @include T_flex_column();
    border:1px solid rgba(134,147,164,1);
    .t{
        width:1.9rem;
        margin-bottom: .4rem;
    }
    P{
        font-size:28px;
        font-family:Microsoft YaHei;
        font-weight:400;
        color:rgba(255,255,255,1);
        line-height:36px;
        text-align: center;
        margin-bottom:.25rem;
    }
    h1{
        font-size: 18px;
        color:#fff;
        span{
            color:#FF3636;
        }
    }
    .fly{
        width:338px;
        margin-top:55px;
    }
}

.ant-form-explain{
    display: none ;
}
.T_content{
    width: 100%;
    flex: 1;
    overflow: hidden;
    box-sizing: border-box;
    background: #464D56;
    border-radius: 0.1rem;
    height: 100%;
    .T_table{
        flex: 1;
        width: 100%;
        overflow: auto;
        border-radius: 10px 10px 0 0;
        background: #505e6f;
        box-sizing: border-box;
        padding: 20px;
        height: 100%;
        .Ta_title{
            font-size: 16px;
            line-height: 30px;
            word-break: break-all;
            text-align: justify;
            padding-left: 20px;
            visibility: initial;
            margin-bottom: 15px;
            color: #c0c4c9;
            em{
                font-style: italic;
                font-weight: bold;
                margin-right: 10px;
                font-size: 18px;
            }
        }
        .Table_l table{
            margin-left: 30px;
            width: calc(100% - 30px);
            border:solid #ffffff;
            border-width:1px 0px 0px 1px;
        }

        .Table_l mark{
            margin-left: 30px;
            margin-top: 8px;
            margin-bottom: 20px;
            display: block;
            background: none;
            color: #c0c4c9;
        }
        .T_dengji{
            text-align: right;
            padding-right: 20px;
            color: #c0c4c9;
            font-size: 20px;
        }

        .Table_l thead tr th:first-child,.Table_l tbody tr td:first-child{
            width: 100px;
            text-align: center;
        }
        .Table_l th{
            // background-color: rgb(192, 192, 192);
        }
        .Table_l th,.Table_l td{
            line-height: 25px;
            font-weight: normal;
            border:solid #ffffff;
            border-width:0px 1px 1px 0px;
            color: #c0c4c9;
        }
        .Table_l td{
            text-align: center;
        }
    }
}
// 按钮样式
.ant-popover-inner-content{
    padding: 0 !important;
    width: 3.7rem;
    height: 5rem;
    .extendMain{
        display: flex;
        flex-direction: column;
        height: 100%;
        .title{
            border-radius: 3px 3px 0px 0px;
            background: #5E6B7D;
            display: flex;
            justify-content: space-between;
            height: 0.46rem;
            align-items: center;
            padding: 0 0.22rem;
            color: #f9f9f9;
            .guanbi{
                width: 0.15rem;
                height: 0.15rem;
                cursor: pointer;
            }
        }
        .content{
            flex: 1;
            background-color: #f1f1f1;
            overflow-y: scroll;
            padding: 0.3rem;
            display: flex;
            flex-direction: column;
            .TextInput{
                flex: 1;
                background-color: #f1f1f1;
                border: none;
            }
            .showCount{
                text-align: right;
                color: #ccc;
            }
            .save{
                width: 1.05rem;
                height: 0.35rem;
                text-align: center;
                line-height: 0.35rem;
                margin: 0.1rem auto 0;
                border-radius: 0.05rem;
                border: 1px solid #0089E9;
                color: #0089E9;
                background-color: #fff;
                cursor: pointer;
            }
        }

    }
}
// 菜单样式
.ant-drawer-content-wrapper{
    width: 4.25rem !important;
}
.ant-drawer-body{
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    padding: 0 !important;
    background-color: #4c6070;
    .ant-menu{
        background-color: #4c6070 !important;
        color: #fff;
        /deep/ .ant-menu-submenu-arrow::before {
            background-color: #fff !important;

        }
        .ant-menu-submenu-arrow::after {
            background-color: #fff !important;

        }
        .ant-menu-submenu-arrow::after{
            background-color: #fff !important;
        }
        .ant-menu-submenu-title{
            font-size: 0.2rem;
        }
        .ant-menu-sub{
            background-color: #546A7c !important;
        }
        .menuTitle{
            display: flex;
            align-items: center;
            height: 38px;
        }
        .activeMenu{
            border-bottom: 2px solid #3dffea;
        }
        .yuan1 {
            display: inline-block;
            width: 14px;
            height: 17.11px;
            background: url('../../../img/list/level1_high.png') no-repeat;
            margin-right: 0.2rem;
        }
        .yuan2 {
            display: inline-block;
            width: 12.59px;
            height: 15.41px;
            background: url('../../../img/list/level2_high.png') no-repeat;
            margin-right: 0.2rem;
        }
        .yuan3 {
            display: inline-block;
            width: 12.86px;
            height: 12.91px;
            background: url('../../../img/list/level3_high.png') no-repeat;
            margin-right: 0.2rem;
        }
        .catalog{
            font-size: 0.2rem;
            text-overflow: ellipsis;
            flex: 1;
            overflow: hidden;
        }
    }
}
.ant-menu-submenu-arrow {
    color: white;
  }
.head_search{
    padding: 0 0.24rem;
    .t1{
        display: flex;
        justify-content: space-between;
        height: 0.95rem;
        align-items: center;
        .m1{
            width: 0.23rem;
            height: 0.23rem;
        }
        .m2{
            width: 0.7rem;
            height: 0.7rem;
        }
        .m3{
            width: 0.1rem;
            height: 0.15rem;
            cursor: pointer;
        }
        i{
            font-size: 0.2rem;
            color: #fff;
        }
    }
    .t2{
        .searchInput{
            input{
                background-color: #4C6070;
                color: #fff;
            }
            svg{
                color: #fff;
            }
        }
    }
}
.Analog_box_footer{
    .BtnComponent{
        display: flex;
        margin-right: 1.7rem;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        .extend{
            display: flex;
            border-right: 2px solid #769FD2;
            margin-right: 0.55rem;
            padding-right: 0.4rem;
            & > div{
                width: 0.4rem;
                height: 0.4rem;
                cursor: pointer;
                margin-right: 0.15rem;
            }
            .luyin{
                background: url(../../../img/luyinPing_def.png) no-repeat scroll center/100% 100%;
                // &:hover{
                //     background: url(../../../img/text_hover.png) no-repeat scroll center/100% 100%;
                // }
            }

            .luyinActive{
                background: url(../../../img/luyinPing_act.png) no-repeat scroll center/100% 100%;
            }
            .text{
                background: url(../../../img/text_default.png) no-repeat scroll center/100% 100%;
                &:hover{
                    background: url(../../../img/text_hover.png) no-repeat scroll center/100% 100%;
                }
            }
            .textActive{
                background: url(../../../img/text_active.png) no-repeat scroll center/100% 100%;
            }
            .notes{
                background: url(../../../img/notes_default.png) no-repeat scroll center/100% 100%;
                &:hover{
                    background: url(../../../img/notes_hover.png) no-repeat scroll center/100% 100%;
                }
            }
            .notesActive{
                background: url(../../../img/notes_active.png) no-repeat scroll center/100% 100%;
            }
            .answer{
                background: url(../../../img/answer_default.png) no-repeat scroll center/100% 100%;
                &:hover{
                    background: url(../../../img/answer_hover.png) no-repeat scroll center/100% 100%;
                }
            }
            .answerActive{
                background: url(../../../img/answer_active.png) no-repeat scroll center/100% 100%;
            }
            
        }
        .backAndNextBtn{
            display: flex;
            & > div{
                width: 1.2rem;
                height: 0.4rem;
                cursor: pointer;
            }
            .back{
                background: url(../../../img/back_default.png) no-repeat scroll center/100% 100%;
                &:hover{
                    background: url(../../../img/back_hover.png) no-repeat scroll center/100% 100%;
                }
                margin-right: 0.2rem;
            }
            .next{
                &:hover{
                    background: url(../../../img/next_hover.png) no-repeat scroll center/100% 100%;
                }
                background: url(../../../img/next_default.png) no-repeat scroll center/100% 100%;
    
            }
        }
    }

}
//菜单开关
.switchMenu{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

// 关于软件
.aboutModal{
    .ant-modal-content{
        border-radius: 10px;
    }
    .ant-modal-body{
        padding: 0px;
    }
}
.about_soft{
    height: 7rem;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    z-index: 200;

    .title{
        text-align: right;
        padding-right: 20px;
        font-size: 20px;
        line-height: 35px;
        background: #525d6c;
        color: #fff;
        i{
            cursor: pointer;
        }
    }
    .content{
        box-sizing: border-box;
        height: calc(100% - 35px);
        overflow: auto;
        padding: 35px;
        margin-bottom: 40px;
        h1{
            font-size: 28px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #333333;
            text-align: center;
            margin-bottom: 40px;
        }
        .sltit{
            padding-left: 25px;
            position: relative;
            &::after{
                content: '';
                position: absolute;
                width: 8px;
                height: 30px;
                background-color: #81d462;
                border-radius: 4px;
                left: 0;
                top: 0;
            }
            h2{
                font-size: 24px;
                font-weight: normal;
                font-stretch: normal;
                letter-spacing: 0px;
                color: #333333;
                line-height: 30px;
                margin-bottom: 20px;
            }
            p{
                font-size: 18px;
                font-weight: normal;
                font-stretch: normal;
                line-height: 32px;
                letter-spacing: 0px;
                color: #666666;
                text-align: justify;
            }
        }
    }
}
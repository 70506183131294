.clearfix {
    *zoom: 1;
}

.clearfix:after {
    content: '.';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.T_container {
    width: 1200px;
    margin: 0 auto;
}

.T_vertical {
    display: inline-block;
    vertical-align: middle;
}

.T_nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: hidden;
}

@mixin T_fixed($top: 0, $right: 0, $bottom: 0, $left: 0) {
    position: fixed;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin T_abs($top: 0, $left: 0) {
    position: absolute;
    left: $left;
    top: $top;
}

@mixin T_wh($width: 100%, $height: 100%) {
    height: $height;
    width: $width;
}

@mixin T_linear($linear...) {
    background: linear-gradient($linear);
    background: -webkit-linear-gradient($linear);
    background: -moz-linear-gradient($linear);
    background: -ms-linear-gradient($linear);
    background: -o-linear-gradient($linear);
}

@mixin T_border($color, $width: 1px, $type: solid) {
    border: $width $type $color;
}

@mixin T_Bottomborder($color, $width: 1px, $type: solid) {
    border-bottom: $width $type $color;
}

@mixin T_border_radius($radius: 50%) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;
}

@mixin T_tansform($trans...) {
    transform: $trans;
    -webkit-transform: $trans;
    -moz-transform: $trans;
    -ms-transform: $trans;
    -o-transform: $trans;
}

@mixin T_shadow($shadow...) {
    box-shadow: $shadow;
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    -ms-box-shadow: $shadow;
    -o-box-shadow: $shadow;
}

@mixin T_flex_row($justify: center, $align: center) {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    align-items: $align;
    justify-content: $justify;
}

@mixin T_flex_column($justify: center, $align: center) {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    align-items: $align;
    justify-content: $justify;
}

@mixin Animation($animate...) {
    animation: $animate;
    -webkit-animation: $animate;
    -moz-animation: $animate;
    -ms-animation: $animate;
    -o-animation: $animate;
}

@mixin T_font_one($a: 0.18rem, $b: 0.3rem, $c: #fff) {
    font-size: $a;
    line-height: $b;
    color: $c;
}





.Btn_ {
    width: 2rem;
    height: .45rem;
    border-radius: 5px;
    color: #fff;
    font-size: .2rem;
    cursor: pointer;
    transition: .1s;
    border: none;

    &.primary {
        background-color: #2c7fe5;

        &:hover {
            background: #186bb0;
        }

        &:active {
            background: #0f5997;
        }
    }

    &.info {
        background-color: transparent;
        border: 1px solid #fff;
        color: #fff;
    }
}

// .ant-spin-container {
//     border-bottom: 1px solid
// }
// videojs
.video-js .vjs-control-bar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    display: none;
}
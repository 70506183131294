.Loading_wrap {
  height: 100%;
}
.Loading_wrap .ant-spin-container {
  height: 100%;
  overflow: hidden;
}
.Loading_wrap .ant-spin-text {
  letter-spacing: 3px;
  color: #495060;
}
.Loading_wrap .ant-spin-dot i {
  background-color: #495060;
}
.Loading_wrap .ant-spin-blur {
  opacity: 0;
}
.Loading_wrap .ant-spin-spinning {
  background-color: #fff;
}
.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100%;
}
